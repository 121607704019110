<template>
    <div>
        <title>Home</title>
        <section class="content-header">
            <h1>
                Main page
            </h1>
            <ol class="breadcrumb">
                <li><a href="#"><i class="fa fa-dashboard"></i> Home</a></li>
                <li class="active">Dashboard</li>
            </ol>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-md-12">
                    <div class="box box-solid">
                        <div class="box-header with-border">
                        <i class="fa fa-text-width"></i>

                        <h3 class="box-title">Home</h3>
                        </div>
                        <!-- /.box-header -->
                        <div class="box-body">
                        <blockquote>
                            <p>Welcome to the main page of Warehouse Inventory Management Information System. Please use this system as well as possible</p>
                        </blockquote>
                        </div>
                        <!-- /.box-body -->
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name:"dashboard"
}
</script>